<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="QuayLai"
                            />
                            <!-- @click="LayThongTinLenhSauKhiTaoLenh" -->
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            TẠO LỆNH TÙY CHỌN
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="px-2 pt-2" style="margin-bottom: 64px">
                <ThongTinLenhTuyChonVue ref="ThongTinLenhTuyChonVue" />

                <ThongTinChieuDiVue ref="ThongTinChieuDiVue" />

                <ThongTinChieuVeVue ref="ThongTinChieuVeVue" />

                <ThongTinMoRongVue ref="ThongTinMoRongVue" />

                <div class="button-bottom row justify-space-between align-center">
                    <DxButton
                        text="Hủy"
                        type="danger"
                        styling-mode="text"
                        @click="QuayLai"
                    />
                    <DxButton
                        text="Tạo lệnh"
                        type="default"
                        styling-mode="contained"
                        @click="TaoLenh()"
                    />
                </div>
            </div>

            <PopupVue
                height="auto"
                v-if="ifPopupMatMaKyLenh"
                v-model:dialog="PopupMatMaKyLenh"
                title="Nhập mã bảo mật"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="XacNhanKyLenh"
                classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
            >
                <template #content>
                    <PopupMatMaKyLenhVue ref="PopupMatMaKyLenhVue" />
                </template>
            </PopupVue>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanNhanLenhSauKhiTaoLenh"
                v-model:dialog="PopupXacNhanNhanLenhSauKhiTaoLenh"
                title=""
                :closeOnOutsideClick="false"
                buttonTextLeft="Đóng"
                class="popup-padding-8"
                @close="XacNhanNhanLenhSauKhiTaoLenh"
                heightScrollView="auto"
            >
                <template #content>
                    <ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import ThongTinLenhTuyChonVue from "./components/ThongTinLenhTuyChon.vue";
import ThongTinChieuDiVue from "./components/ThongTinChieuDi.vue";
import ThongTinChieuVeVue from "./components/ThongTinChieuVe.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import PopupMatMaKyLenhVue from "./components/PopupMatMaKyLenh.vue";
import ThongTinMoRongVue from "./components/ThongTinMoRong.vue";
import ThongBaoVue from "../../../components/_Common/ThongBao.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        PopupVue,
        PopupMatMaKyLenhVue,
        DxButton,
        ThongTinLenhTuyChonVue,
        ThongTinChieuDiVue,
        ThongTinChieuVeVue,
        ThongTinMoRongVue,
        ThongBaoVue,
    },
    data() {
        return {
            ifPopupMatMaKyLenh: false,
            PopupMatMaKyLenh: false,
            ifPopupXacNhanNhanLenhSauKhiTaoLenh: false,
            PopupXacNhanNhanLenhSauKhiTaoLenh: false,
            ParamThongBao: {
                state: "Success",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    computed: {
        ...mapGetters({
            SoLuongLenhKyLenhToiDa: "DVVTLenh/SoLuongLenhKyLenhToiDa",
        }),
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXe: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachLaiXe || [];
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinChieuDi: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChieuDi || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinChieuVe: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChieuVe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChieuVe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXeChieuDi: {
            get() {
                return (
                    this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachLaiXeChieuDi || {}
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXeChieuVe: {
            get() {
                return (
                    this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachLaiXeChieuVe || {}
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuVe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachTuyenVanChuyen: {
            get() {
                return (
                    this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachTuyenVanChuyen ||
                    []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachTuyenVanChuyen",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon",
                    data: data,
                });
            },
        },
        resetPage: {
            get() {
                return this.$store.state.LenhTuyChon.resetPage;
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "resetPage",
                    data: data,
                });
            },
        },
        CheckDuDieuKienLaiXeDi_1() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeDi_1"];
        },
        CheckDuDieuKienLaiXeDi_2() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeDi_2"];
        },
        CheckDuDieuKienLaiXeDi_3() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeDi_3"];
        },
        CheckDuDieuKienLaiXeVe_1() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_1"];
        },
        CheckDuDieuKienLaiXeVe_2() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_2"];
        },
        CheckDuDieuKienLaiXeVe_3() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_3"];
        },
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe",
                    data: data,
                });
            },
        },
        DanhSachLenhCuaLaiXeSauKhiKyLenh: {
            get() {
                return this.$store.state.DVVTLenh.DanhSachLenhCuaLaiXeSauKhiKyLenh || [];
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachLenhCuaLaiXeSauKhiKyLenh",
                    data: data,
                });
            },
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh() {
            return (
                this.$store.state.LenhTuyChon.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh ||
                {}
            );
        },
    },
    watch: {
        PopupMatMaKyLenh() {
            if (!this.PopupMatMaKyLenh) {
                this.NgatDOMPopup("ifPopupMatMaKyLenh");
            }
        },
        PopupXacNhanNhanLenhSauKhiTaoLenh() {
            if (!this.PopupXacNhanNhanLenhSauKhiTaoLenh) {
                this.NgatDOMPopup("ifPopupXacNhanNhanLenhSauKhiTaoLenh");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        QuayLai() {
            this.$store.commit("LenhTuyChon/resetTaoLenhTuyChon");
            this.resetPage = true;
            this.$router.go(-1);
        },
        async LayDanhSachLaiXe() {
            try {
                this.KyLenhTuyChon_DanhSachLaiXe = (
                    await this.$store.dispatch(
                        "LenhTuyChon/Get_DanhSachLaiXeTaoLenhTuyChon",
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách lái xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách nhà xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        async LayDanhSachHangGPLX() {
            try {
                await this.$store.dispatch("ThongTinLaiXe/Get_DanhSachHangGPLX");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách hạng GPLX bị lỗi! Lỗi hệ thống client.",
                );
            }
        },
        async LayDanhSachLoaiLenh() {
            try {
                await this.$store.dispatch("LenhTuyChon/Get_DanhSachLoaiLenh");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách loại lệnh vận chuyển bị lỗi! Lỗi hệ thống client.",
                );
            }
        },
        async LayDanhSachLuongTuyen() {
            try {
                this.KyLenhTuyChon_DanhSachTuyenVanChuyen = (
                    await this.$store.dispatch("LenhTuyChon/Get_DanhSachLuongTuyen")
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách tuyến vận chuyển thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        async LayDanhSachXe() {
            try {
                this.KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon = (
                    await this.$store.dispatch(
                        "LenhTuyChon/Get_DanhSachPhuongTienTaoLenhTuyChon",
                        {
                            query: "00000000-0000-0000-0000-000000000000",
                        },
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        async TaoLenh() {
            // component ThongTinLenhTuyChonVue
            let validateThongTinLenhTuyChon = this.$refs.ThongTinLenhTuyChonVue.$refs.formValidation.instance.validate();
            let validateXeNghi = this.$refs.ThongTinLenhTuyChonVue.$refs
                .formValidationXeNghi
                ? this.$refs.ThongTinLenhTuyChonVue.$refs.formValidationXeNghi.instance.validate()
                : null;
            let validateTuyenVanChuyen = this.$refs.ThongTinLenhTuyChonVue.$refs.formValidationTuyenVanChuyen.instance.validate();
            let validateHanPhuHieu = this.$refs.ThongTinLenhTuyChonVue.$refs.formValidationHanPhuHieu.instance.validate();
            let validateBKS = this.$refs.ThongTinLenhTuyChonVue.$refs.formValidationBKS.instance.validate();
            let validateThongTinXe = this.$refs.ThongTinLenhTuyChonVue.$refs.formValidationThongTinXe.instance.validate();

            // validate ThongTinLenhTuyChonVue
            if (!validateThongTinLenhTuyChon.isValid) {
                this.$Helper.Common.autoFocusError(validateThongTinLenhTuyChon);
                return;
            }
            if (validateXeNghi) {
                if (!validateXeNghi.isValid) {
                    this.$Helper.Common.autoFocusError(validateXeNghi);
                    return;
                }
            }
            if (!validateTuyenVanChuyen.isValid) {
                this.$Helper.Common.autoFocusError(validateTuyenVanChuyen);
                return;
            }
            if (!validateHanPhuHieu.isValid) {
                this.$Helper.Common.autoFocusError(validateHanPhuHieu);
                return;
            }
            if (!validateBKS.isValid) {
                this.$Helper.Common.autoFocusError(validateBKS);
                return;
            }
            if (!validateThongTinXe.isValid) {
                this.$refs.ThongTinLenhTuyChonVue.openSuaThongTinXe = true;
                this.$Helper.Common.autoFocusError(validateThongTinXe);
                return;
            }

            let SoLenhKyChieuDi = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi
                .length;
            let SoLenhKyChieuVe = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe
                .length;

            // validate ThongTinChieuDiVue
            if (SoLenhKyChieuDi > 0) {
                // component ThongTinChieuDiVue
                let validateThongTinChieuDi = this.$refs.ThongTinChieuDiVue.$refs.formValidation.instance.validate();

                if (!validateThongTinChieuDi.isValid) {
                    this.$Helper.Common.autoFocusError(validateThongTinChieuDi);
                    return;
                }
            }

            // validate ThongTinChieuVeVue
            if (SoLenhKyChieuVe > 0) {
                // component ThongTinChieuVeVue
                let validateThongTinChieuVe = this.$refs.ThongTinChieuVeVue.$refs.formValidation.instance.validate();

                if (!validateThongTinChieuVe.isValid) {
                    this.$Helper.Common.autoFocusError(validateThongTinChieuVe);
                    return;
                }
            }

            if (
                this.CheckDuDieuKienLaiXeDi_1 == "HetHan" ||
                this.CheckDuDieuKienLaiXeDi_2 == "HetHan" ||
                this.CheckDuDieuKienLaiXeDi_3 == "HetHan" ||
                this.CheckDuDieuKienLaiXeVe_1 == "HetHan" ||
                this.CheckDuDieuKienLaiXeVe_2 == "HetHan" ||
                this.CheckDuDieuKienLaiXeVe_3 == "HetHan"
            ) {
                this.$Helper.ThongBaoPopup({
                    type: "Warning",
                    message_title: "Thông tin lái xe không đủ điều kiện!",
                    message: "Vui lòng kiểm tra lại.",
                });
                return;
            }

            if (SoLenhKyChieuDi == 0 && SoLenhKyChieuVe == 0) {
                this.$Helper.ThongBaoPopup({
                    type: "Warning",
                    message_title: "Vui lòng chọn ngày ký lệnh chiều đi hoặc về!",
                    message: "",
                });
                return;
            }

            if (SoLenhKyChieuDi + SoLenhKyChieuVe > this.SoLuongLenhKyLenhToiDa) {
                return this.$Helper.ThongBaoPopup({
                    type: "Error",
                    message_title: "Thông tin ký lệnh không hợp lệ!",
                    message: `Số lệnh được phép ký trong 1 phiên không được vượt quá ${this.SoLuongLenhKyLenhToiDa}!`,
                });
            }

            let {
                isTrungGioXB,
                gioXbTrung,
                ngayXbTrung,
            } = this.KiemTraTrungNgayGioKyLenh();
            if (!isTrungGioXB) {
                this.$Helper.ThongBaoPopup({
                    type: "Warning",
                    message_title:
                        "Tồn tại Ngày và Giờ xuất bến chiều đi và về trùng nhau!",
                    message: `\t* Nốt/Tài bị trùng:${gioXbTrung}\n\t* Ngày bị trùng:${ngayXbTrung}`,
                });
                return;
            }

            // else if (this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi.length == 0) {
            //     this.$Helper.ThongBaoPopup({
            //         type: "Warning",
            //         message_title: "Vui lòng chọn ngày ký lệnh chiều đi!",
            //         message: "",
            //     });
            //     return;
            // } else if (this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe.length == 0) {
            //     this.$Helper.ThongBaoPopup({
            //         type: "Warning",
            //         message_title: "Vui lòng chọn ngày ký lệnh chiều về!",
            //         message: "",
            //     });
            //     return;
            // }

            let rsCapNhatThongTinGiayToXe = await this.CapNhatThongTinGiayToXe();
            if (rsCapNhatThongTinGiayToXe == true) {
                this.showPopup("PopupMatMaKyLenh");
            }
        },
        KiemTraTrungNgayGioKyLenh() {
            let isTrungGioXB = false;
            let gioXbTrung = "";
            let ngayXbTrung = "";

            (this.KyLenhTuyChon_ThongTinChieuDi?.GioXB || []).forEach((e) => {
                let check = this.KyLenhTuyChon_ThongTinChieuVe?.GioXB.some((x) => e == x);
                if (check) {
                    isTrungGioXB = true;
                    gioXbTrung += `\n\t\t- ${e}`;
                }
            });
            if (!isTrungGioXB) {
                return { isTrungGioXB: true, gioXbTrung };
            } else {
                let check = true;
                let DanhSachNgayXuatBenChieuDi = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi.map(
                    (e) => new Date(e.NgayDuong).toISOString(),
                );
                let DanhSachNgayXuatBenChieuVe = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe.map(
                    (e) => new Date(e.NgayDuong).toISOString(),
                );
                const duplicates = DanhSachNgayXuatBenChieuDi.filter((element) =>
                    DanhSachNgayXuatBenChieuVe.includes(element),
                );
                duplicates.forEach((e) => {
                    ngayXbTrung += `\n\t\t- ${this.$Helper.Common.formatDate(e)}`;
                });
                check = duplicates.length == 0;
                return { isTrungGioXB: check, gioXbTrung, ngayXbTrung };
            }
        },
        async CapNhatThongTinGiayToXe() {
            try {
                let KyLenhTuyChon_ThongTinChung = this.KyLenhTuyChon_ThongTinChung;
                const thongTinGiayToXe = {
                    iD_DnvtTuyen: KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen,
                    iD_DnvtXe: KyLenhTuyChon_ThongTinChung.ID_DnvtXe,
                    hanBaoHiem: KyLenhTuyChon_ThongTinChung.HanBaoHiemXe,
                    hanDangKiem: KyLenhTuyChon_ThongTinChung.HanDangKiemXe,
                    hanBienNhanTheChap: KyLenhTuyChon_ThongTinChung.HanBienNhanTheChap,
                    hanPhuHieu: KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen,
                };
                let result = await this.$store.dispatch(
                    "ThongTin/CapNhatThongTinGiayToXe",
                    thongTinGiayToXe,
                );
                if (result.status == true) {
                    // this.$Helper.ThongBaoToast(
                    //     "success",
                    //     "Cập nhật thông tin giấy tờ xe thành công!",
                    // );
                    return true;
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                        message: `${result.message}`,
                    });
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoPopup({
                    message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                    message: "Lỗi hệ thống",
                });
            }
        },

        async XacNhanKyLenh(param) {
            if (!param) {
                this.PopupMatMaKyLenh = false;
                return;
            }
            try {
                let validMK = this.$refs.PopupMatMaKyLenhVue.$refs.validateMaBaoMat.instance.validate();
                if (!validMK.isValid) {
                    return this.$Helper.Common.autoFocusError(validMK);
                } else {
                    try {
                        this.$startLoading;
                        var self = this;
                        let MaBaoMat = this.$refs.PopupMatMaKyLenhVue.MaBaoMat;
                        let KyLenhTuyChon_ThongTinChung = this
                            .KyLenhTuyChon_ThongTinChung;

                        let KyLenhTuyChon_ThongTinChieuDi = this
                            .KyLenhTuyChon_ThongTinChieuDi;
                        let KyLenhTuyChon_ThongTinChieuVe = this
                            .KyLenhTuyChon_ThongTinChieuVe;
                        let KyLenhTuyChon_DanhSachLaiXeChieuDi = this.KyLenhTuyChon_DanhSachLaiXeChieuDi.filter(
                            (e) => !!e,
                        ).map((e) => e.ID_DnvtLaiXe);
                        let KyLenhTuyChon_DanhSachLaiXeChieuVe = this.KyLenhTuyChon_DanhSachLaiXeChieuVe.filter(
                            (e) => !!e,
                        ).map((e) => e.ID_DnvtLaiXe);
                        let SoLenhKyChieuDi = this
                            .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi.length;
                        let SoLenhKyChieuVe = this
                            .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe.length;

                        let thongTinKyLenh = {
                            DanhSachThongTin: [],
                            PhatHanhLenh: KyLenhTuyChon_ThongTinChung.KyDienTu,
                            MaBaoMat: MaBaoMat,
                            KyLenhKhiCanBoXacNhanKiemTraXe:
                                this.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    this.$t(
                                        "MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe",
                                    )
                                ] == "true"
                                    ? this.KyLenhTuyChon_ThongTinChung
                                          .TuDongKyLenhKhiCanBoXacNhanKiemTraXe
                                    : false,
                            PhanQuyenDuLieuChinhSua: this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.filter(
                                (e) => e.select,
                            ).map((e) => e.MaThuocTinh),
                        };
                        if (SoLenhKyChieuDi > 0) {
                            KyLenhTuyChon_ThongTinChieuDi.GioXB.forEach((e) => {
                                thongTinKyLenh.DanhSachThongTin.push({
                                    ID_DnvtTuyen:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen || null,
                                    ID_LoTrinh:
                                        KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh
                                            .length > 0
                                            ? KyLenhTuyChon_ThongTinChung
                                                  .DanhSachLoTrinh[0]?.ID_LoTrinh
                                            : null,
                                    ID_DnvtXe:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtXe || null,
                                    ID_DnvtXe_DuocDiThay:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay ||
                                        null,
                                    ID_LoaiLenh:
                                        KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen ||
                                        null,
                                    ID_LaiXeNhanLenh:
                                        KyLenhTuyChon_DanhSachLaiXeChieuDi.length > 0
                                            ? KyLenhTuyChon_DanhSachLaiXeChieuDi[0]
                                            : null,
                                    ID_NhaXe: KyLenhTuyChon_ThongTinChung.IdNhaXe || null,
                                    GioXuatBen: e
                                        ? this.$Helper.getUTCDateFromTimeString(e)
                                        : null,
                                    NgayXuatBens:
                                        this
                                            .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi
                                            .length > 0
                                            ? this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi.map(
                                                  (item) => item.Ngay,
                                              )
                                            : [],
                                    ID_LaiXeDiCungs:
                                        KyLenhTuyChon_DanhSachLaiXeChieuDi.length > 1
                                            ? KyLenhTuyChon_DanhSachLaiXeChieuDi.filter(
                                                  (item, index) => index !== 0,
                                              )
                                            : [],
                                    NhanVienPhucVus:
                                        KyLenhTuyChon_ThongTinChieuDi.PhuXe != null
                                            ? KyLenhTuyChon_ThongTinChieuDi.PhuXe.split(
                                                  ",",
                                              )
                                            : [],
                                    LaLenhCoDinh: true,
                                    TaoKeHoach:
                                        KyLenhTuyChon_ThongTinChung.LuuChuyenDiDuKien ||
                                        false,
                                });
                            });
                        }
                        if (SoLenhKyChieuVe > 0) {
                            KyLenhTuyChon_ThongTinChieuVe.GioXB.forEach((e) => {
                                thongTinKyLenh.DanhSachThongTin.push({
                                    ID_DnvtTuyen:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen || null,
                                    ID_LoTrinh:
                                        KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh
                                            .length > 0
                                            ? KyLenhTuyChon_ThongTinChung
                                                  .DanhSachLoTrinh[1]?.ID_LoTrinh
                                            : null,
                                    ID_LoaiLenh:
                                        KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen ||
                                        null,
                                    ID_DnvtXe:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtXe || null,
                                    ID_DnvtXe_DuocDiThay:
                                        KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay ||
                                        null,
                                    ID_LaiXeNhanLenh:
                                        KyLenhTuyChon_DanhSachLaiXeChieuVe.length > 0
                                            ? KyLenhTuyChon_DanhSachLaiXeChieuVe[0]
                                            : null,
                                    ID_NhaXe: KyLenhTuyChon_ThongTinChung.IdNhaXe || null,
                                    GioXuatBen: e
                                        ? this.$Helper.getUTCDateFromTimeString(e)
                                        : null,
                                    NgayXuatBens:
                                        this
                                            .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe
                                            .length > 0
                                            ? this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe.map(
                                                  (item) => item.Ngay,
                                              )
                                            : [],
                                    ID_LaiXeDiCungs:
                                        KyLenhTuyChon_DanhSachLaiXeChieuVe.length > 1
                                            ? KyLenhTuyChon_DanhSachLaiXeChieuVe.filter(
                                                  (item, index) => index !== 0,
                                              )
                                            : [],
                                    NhanVienPhucVus:
                                        KyLenhTuyChon_ThongTinChieuVe.PhuXe != null
                                            ? KyLenhTuyChon_ThongTinChieuVe.PhuXe.split(
                                                  ",",
                                              )
                                            : [],
                                    LaLenhCoDinh: true,
                                    TaoKeHoach:
                                        KyLenhTuyChon_ThongTinChung.LuuChuyenDiDuKien ||
                                        false,
                                });
                            });
                        }
                        let result = await this.$store.dispatch(
                            "LenhTuyChon/KyLenhTuyChon",
                            thongTinKyLenh,
                        );
                        let thongBaoTonTaiLenh = ``;
                        if (result.status) {
                            if (result.data && result.data.length > 0) {
                                result.data.forEach((item, i) => {
                                    thongBaoTonTaiLenh +=
                                        i == 0
                                            ? `- Thông tin lỗi: \n+ ${item.ThongTinLoi}\n`
                                            : `+ ${item.ThongTinLoi}\n`;
                                });
                                this.$Helper.ThongBaoPopup({
                                    type: "Warning",
                                    message_title: "Thông tin kết quả ký lệnh!",
                                    message: `${thongBaoTonTaiLenh}`,
                                });
                            } else {
                                // this.$Helper.ThongBaoToast(
                                //     "success",
                                //     "",
                                // );
                                this.ParamThongBao.title = "Tạo lệnh thành công!";
                                this.ParamThongBao.message =
                                    "Bạn có muốn tiếp tục nhận lệnh không?";
                                this.ParamThongBao.state = "Success";
                                this.showPopup("PopupXacNhanNhanLenhSauKhiTaoLenh");
                            }
                            localStorage.setItem(
                                "isChuyenDiDuKien",
                                KyLenhTuyChon_ThongTinChung.LuuChuyenDiDuKien,
                            );
                            localStorage.setItem(
                                "isKyLenh",
                                KyLenhTuyChon_ThongTinChung.KyDienTu,
                            );
                            this.PopupMatMaKyLenh = false;
                        } else {
                            if (result.errorCode == 401) {
                                this.$Helper.ThongBaoPopup({
                                    type: "Warning",
                                    message_title: "Tạo lệnh thất bại!",
                                    message: `${result.message}`,
                                });
                            } else {
                                this.$Helper.ThongBaoPopup({
                                    message_title: "Tạo lệnh thất bại!",
                                    message: `- Thông tin lỗi: ${result.message}`,
                                });
                            }
                        }
                    } catch (error) {
                        console.log(
                            "🚀 ~ file: index.vue:725 ~ XacNhanKyLenh ~ error:",
                            error,
                        );
                        this.$Helper.ThongBaoPopup({
                            message_title: "Tạo lệnh thất bại!",
                            message: "Lỗi hệ thống",
                        });
                    } finally {
                        this.$stopLoading;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t("MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe"),
                        ],
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        XacNhanNhanLenhSauKhiTaoLenh(bool) {
            if (bool) {
                this.LayThongTinLenhSauKhiTaoLenh();
            } else {
                this.QuayLai();
            }
        },
        async LayThongTinLenhSauKhiTaoLenh() {
            try {
                this.DanhSachLenhCuaLaiXeSauKhiKyLenh = [];
                let DanhSachNgayKyLenh = [];
                let DanhSachTatCaNgayKyLenh = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi.map(
                    (e) => e.Ngay,
                ).concat(
                    this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe.map(
                        (e) => e.Ngay,
                    ),
                );
                DanhSachTatCaNgayKyLenh.forEach((item) => {
                    let check = DanhSachNgayKyLenh.some((e) => e - item == 0);
                    if (!check) {
                        DanhSachNgayKyLenh.push(item);
                    }
                });
                DanhSachNgayKyLenh.sort((a, b) => a - b);
                for (let index = 0; index < DanhSachNgayKyLenh.length; index++) {
                    if (this.DanhSachLenhCuaLaiXeSauKhiKyLenh.length > 5) {
                        break;
                    }
                    let item = DanhSachNgayKyLenh[index];
                    let tmpDanhSachLenhLaiXe = await this.LayLenhCuaLaiXeSauKhiKyLenh(
                        item,
                    );
                    this.DanhSachLenhCuaLaiXeSauKhiKyLenh.push(...tmpDanhSachLenhLaiXe);
                }
                this.QuayLai();
                setTimeout(() => {
                    this.$router.push("/Nhan-Lenh-Sau-Khi-Tao-Lenh");
                }, 100);
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        async LayLenhCuaLaiXeSauKhiKyLenh(NgayXuatBen) {
            try {
                let query = {
                    timKiem: null,
                    idLuongTuyen: null,
                    ngayXuatBenKeHoach: this.$Helper.Common.getEndDay(NgayXuatBen),
                    danhSachGioXuatBen: [],
                };
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayLenhCuaLaiXeSauKhiKyLenh",
                    {
                        query,
                    },
                );
                return rs;
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue:789 ~ LayLenhCuaLaiXeSauKhiKyLenh ~ error:",
                    error,
                );
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            if (this.resetPage) {
                this.KyLenhTuyChon_ThongTinChung.TiepTucNhanLenhSauKhiTaoLenh = localStorage.getItem(
                    "TiepTucNhanLenhSauKhiTaoLenhTuyChon",
                );
                this.KyLenhTuyChon_ThongTinChung.TiepTucNhanLenhSauKhiTaoLenh = !this
                    .KyLenhTuyChon_ThongTinChung.TiepTucNhanLenhSauKhiTaoLenh
                    ? true
                    : this.KyLenhTuyChon_ThongTinChung.TiepTucNhanLenhSauKhiTaoLenh ==
                      "true";
                this.LayDanhSachLoaiLenh();
                this.LayDanhSachHangGPLX();
                this.LayDanhSachNhaXe();
                this.LayDanhSachLaiXe();

                this.LayDanhSachLuongTuyen();
                this.LayDanhSachXe();
                this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();
                this.$store.dispatch("DVVTLenh/LayCauHinhSuDung");

                this.resetPage = false;
            }
        });
    },
    mounted() {},
};
</script>
<style scoped></style>
